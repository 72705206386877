<template>
  <div class="container mt-3">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black"
            ><i class="fas fa-home"></i
          ></router-link>
        </li>
        <li class="breadcrumb-item active">Kursus</li>
      </ol>
    </nav>
    <div class="p-3 bg-white mb-4" v-if="courseData != null">
      <div        
        class="video-container"
        v-html="courseData.attributes.embed_html"
      ></div>
      <h4 class="mb-0 mt-3 fw-bold">
        {{ courseData != null ? courseData.attributes.course_name : "" }}
      </h4>
      <small class="extra-small text-muted">{{
        getDate(courseData.attributes.createdAt)
      }}</small>
      <p class="mt-3 fw-regular">
        {{ courseData != null ? courseData.attributes.description : "" }}
      </p>
    </div>
  </div>
</template>
<script>
import API from "./../../utils/API";
import moment from "moment";
import { mapGetters } from 'vuex'

export default {
  name: "ViewStreamPage",
  data() {
    return {
      courseData: null,
      client: null,
    };
  },
  watch: {
    async courseData() {
      if (this.courseData === null) return;

      console.log(this.courseData);      
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    getDate(date) {
      return date != undefined ? moment(date).format("LLLL") : "";
    },
    getProgram(id) {
      API.get(`courses/${id}?populate=%2A`).then((res) => {
        this.courseData = res.data.data;
      });
    },
  },

  async mounted() {    
    await this.getProgram(
      this.$route.params && this.$route.params.id ? this.$route.params.id : null
    );
  },
};
</script>
<style lang="scss" scoped>
.video-container {
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>